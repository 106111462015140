import { NavLink, useLocation } from "react-router-dom";

const links = [
	{
		href: "/",
		text: "Orgastro",
	},
	{
		href: "/oferta",
		text: "Oferta",
	},
	{
		href: "/efektywne-zarzadzanie",
		text: "Efektywne zarządzanie",
	},
	{
		href: "/kontakt",
		text: "Kontakt",
	},
];

export function NavigationLinks({ closeMenu }: { closeMenu?: () => void }) {
	const location = useLocation();

	return (
		<>
			{links.map((link) => (
				<div
					key={link.href}
					className="px-2 hover:opacity-90 relative h-12 flex items-center justify-center group"
					onClick={closeMenu}
				>
					<NavLink to={link.href}>{link.text}</NavLink>

					<div
						className={
							"h-1 bg-[#dc1f26] absolute bottom-0 w-[calc(100%-0.25rem)] left-0 duration-200 translate-y-full opacity-0 group-hover:opacity-100" +
							(location.pathname === link.href ? " !opacity-100 translate-y-0" : "")
						}
					/>
				</div>
			))}
		</>
	);
}
